import React, {useEffect, useState} from 'react'
import {ListApi} from '../../lib/Api'
import {loopbackFilters} from '../../lib/Api/loopback'
import Filter from '../../lib/CrudComponents/Filter'
import {ticketApi, sharedBoxApi, categoryApi} from '../../services/Api'
import List from './TicketList'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import {TextField} from 'final-form-material-ui'
import {Field} from 'react-final-form'
import {DndProvider} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import {useDrop} from 'react-dnd'
import SelectField from '../../lib/Fields/Select'
import DatePicker from '../../lib/Fields/DatePicker'
import {toPairs} from 'ramda'
import moment from 'moment'
import Sort from '@material-ui/icons/Sort'
import {Tooltip, IconButton} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import {setOrder, getOrder} from '../../utils/configUser'

const getCurrentId = (params) => {
  if (params.childId) {
    return params.childId
  }
  return params.id
}

function TicketFilter(props) {
  const [responsibles, setResponsibles] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  useEffect(() => {
    async function fetchData() {
      const sharedBoxId = getCurrentId(props.match.params)
      const responseResposibles = await sharedBoxApi.listUserMails(
        sharedBoxId,
        {filter: {where: {removed: false}, orderBy: 'email ASC'}})
      if (responseResposibles.ok) {
        setResponsibles(responseResposibles.data)
      }
      const responseCategories = await sharedBoxApi.listCategories(
        sharedBoxId,
        {filter: {where: {removed: false}, orderBy: 'name ASC'}})
      if (responseCategories.ok) {
        setCategories(responseCategories.data)
      }
    }
    fetchData()
  }, [props.history.location.pathname])

  const categoryChange = async (event) => {
    const categoryId = event.target.value
    if (categoryId) {
      const responseSubcategories = await categoryApi.listSubcategories(
        categoryId,
        {filter: {where: {removed: false}, orderBy: 'name ASC'}})
      if (responseSubcategories.ok) {
        setSubcategories(responseSubcategories.data)
      } else {
        setSubcategories([])
      }
    } else {
      setSubcategories([])
    }
  }

  return (
    <Filter
     labels={{find: 'Buscar', clear: 'Limpar'}}>
      <Grid spacing={24} container>
        <Grid item sm={5}>
          <Field
            fullWidth
            component={TextField}
            label='Assunto/Ticket'
            name='name'/>
        </Grid>
        <Grid item sm={3}>
          <Field
            fullWidth
            component={TextField}
            label='Identificador'
            name='identifier'/>
        </Grid>
        <Grid item sm={2}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data Criação Início'
            name='startCreated' />
        </Grid>
        <Grid item sm={2}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data Criação Fim'
            name='endCreated' />
        </Grid>
        <Grid item sm={3}>
          <Field
            fullWidth
            onChange={(e) => categoryChange(e)}
            component={SelectField}
            options={categories.map(category => ({
              value: category.id, label: category.name
            }))}
            label='Categoria'
            name='categoryId' />
        </Grid>
        <Grid item sm={3}>
          <Field
            fullWidth
            disabled={!subcategories.length}
            component={SelectField}
            options={subcategories.map(subcategory => ({
              value: subcategory.id, label: subcategory.name
            }))}
            label='Subcategoria'
            name='subcategoryId' />
        </Grid>
        <Grid item sm={3}>
          <Field
            fullWidth
            component={SelectField}
            options={responsibles.map(responsible => ({
              value: responsible.id, label: responsible.email
            }))}
            label='Responsável'
            name='responsibleId' />
        </Grid>
        <Grid item sm={3}>
          <Field
            fullWidth
            component={TextField}
            label='Aberto por'
            name='senderName'/>
        </Grid>
      </Grid>
    </Filter>
  )
}

function TicketList({...props}) {
  const [sharedBox, setSharedBox] = useState(null)
  const [counts, setCounts] = useState({})
  const [value, setValue] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    let isSubscribed = true
    async function fetchData(sharedBoxId) {
      const responseSharedBox = await sharedBoxApi.getOne(sharedBoxId)
      if (responseSharedBox.ok) {
        const statuses = responseSharedBox.data.statuses.filter(s => !s.removed)
        if (isSubscribed) {
          setSharedBox({
            ...responseSharedBox.data,
            statuses
          })
        }
        const responseTickets = await ticketApi.counts(sharedBoxId)
        if (responseTickets.ok) {
          if (isSubscribed) {
            setCounts(responseTickets.data)
          }
        }
      }
    }
    const sharedBoxId = getCurrentId(props.match.params)
    fetchData(sharedBoxId)
    return () => isSubscribed = false
  }, [props.history.location.pathname, props.rerender])

  function MyTab ({label, value}) {
    const [, drop] = useDrop({
      accept: 'row',
      drop: async (item) => {
        if (item.statusId !== value) {
          const response = await ticketApi.update(item.id, {statusId: value})
          if (response.ok) {
            handleChange(null, value)
          } else {
            props.history.push(`/tickets/${item.id}?message=Necessário Categoria e SubCategoria&statusId=${value}`)
          }
        }
      }
    })
    return (
      <div ref={drop}>
        <Tab label={label} onClick={(e) => handleChange(e, value)} />
      </div>
    )
  }

  const listOptions = {
    defaultOrder: `${getOrder()} DESC`,
    fields: {
      status: {
        label: 'Status',
      },
      name: {
        label: 'Assunto/Ticket'
      },
      responsible: {
        label: 'Responsável',
        noOrder: true,
      },
      lastUpdate: {
        label: 'Última Atualização'
      },
      messages: {
        label: 'Emails',
        noOrder: true,
        format: messages => messages? messages.length : '-'
      }
    }
  }

  const getPageDecorator = async ({getPage}) => {
    const items = await getPage()
    return items
  }

  const urlFilterToObject = filter => {
    if (!filter) return {}
    return filter.split(',')
      .reduce((obj, keyValue) => {
        const [key, value] = keyValue.split('=')
        return {...obj, [key]: value}
      }, {})
  }

  const createFilters = (values) =>
    toPairs(values)
      .map(([key, value]) => `${key}=${value}`)
      .join(',')

  async function handleChange(event, newValue) {
    const sharedBoxId = getCurrentId(props.match.params)
    const response = await ticketApi.counts(sharedBoxId)
    if (response.ok) {
      setCounts(response.data)
    }
    setValue(newValue)
    const {history, location: {pathname}} = props
    if (newValue === 0) {
      history.push(`${pathname}`)
    } else {
      const params = new URLSearchParams(props.location.search)
      const filter = urlFilterToObject(params.get('filters'))
      filter['statusId'] = newValue
      const filterURL = createFilters(filter)
      history.push(`${pathname}?filters=${filterURL}`)
    }
  }

  const setStatusId = (value) => {
    if (typeof value === 'undefined' )
      return 0
    if (value === null) {
      return -1
    }
    return +value
  }

  const transformWhere = where => {
    const copy = {...where}
    setValue(setStatusId(where.statusId))
    if (where.startCreated && where.endCreated) {
      const date = moment(where.startCreated).format('YYYY-MM-DD')
      const nextDay = moment(where.endCreated).add(1, 'days').format('YYYY-MM-DD')
      copy.created = { between: [ date, nextDay ] }
    } else if (where.startCreated) {
      const date = moment(where.startCreated).format('YYYY-MM-DD')
      copy.created = { gte: date }
    } else if (where.endCreated) {
      const nextDay = moment(where.endCreated).add(1, 'days').format('YYYY-MM-DD')
      copy.created = { lte: nextDay }
    }
    delete copy.startCreated
    delete copy.endCreated
    return copy
  }

  const displayLabel = (label, key) => {
    if (key in counts && counts[key]) {
      return `${label} (${counts[key]})`
    }
    return label
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handle = (order) => {
    setOrder(order)
    const {history, location: {pathname}} = props
    const params = new URLSearchParams(props.location.search)
    const filter = urlFilterToObject(params.get('filters'))
    filter['order'] = `${getOrder()} DESC`
    const filterURL = createFilters(filter)
    history.push(`${pathname}?filters=${filterURL}`)
    setAnchorEl(null)
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const orderButton = () => {
    return (
      <>
      <Tooltip title='Ordenar'>
        <IconButton onClick={handleMenu}
          aria-label="Ordenar">
          <Sort />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={() => handle('created')} selected={getOrder() === 'created'}>Criação</MenuItem>
        <MenuItem onClick={() => handle('lastUpdate')} selected={getOrder() === 'lastUpdate'}>Modificação</MenuItem>
      </Menu>
      </>)
  }

  return (!!sharedBox) ?
    (<DndProvider backend={HTML5Backend}>
      <ListApi
        filtersConfig={{
          name: loopbackFilters.ilike,
          senderName: loopbackFilters.ilike,
          identifier: loopbackFilters.ilike
        }}
        getPageDecorator={getPageDecorator}
        transformWhere={transformWhere}
        api={ticketApi}>
        {
          ({getPage, getCount}) => (
            <div>
              <TicketFilter {...props}/>
              <AppBar position='static' color='inherit'>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item sm={11}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor='primary'
                      variant='scrollable'
                      scrollButtons='auto'>
                      <Tab label={displayLabel('Todos', 'all')} value={0} />
                      <Tab label={displayLabel('Sem Status', 'null')} value={-1} />
                      {sharedBox.statuses.map((status) => (
                        <MyTab key={status.id} label={displayLabel(status.label, status.id)} value={status.id} />
                      ))}
                    </Tabs>
                  </Grid>
                  <Grid item sm={1}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                      {orderButton()}
                    </Grid>
                  </Grid>
                </Grid>
              </AppBar>
              <List
                {...props}
                getPage={getPage}
                getCount={getCount}
                listOptions={listOptions} />
            </div>
          )
        }
      </ListApi>
    </DndProvider>)
    : null
}

export default (TicketList)
