import React, {useEffect, useState} from 'react'
import HomeIcon from '@material-ui/icons/Home'
import AccountBalance from '@material-ui/icons/AccountBalance'
import AddBox from '@material-ui/icons/AddBox'
import Mail from '@material-ui/icons/Mail'
import Person from '@material-ui/icons/Person'
import Category from '@material-ui/icons/Category'
import WrapText from '@material-ui/icons/WrapText'
import Receipt from '@material-ui/icons/Receipt'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import logo from '../../images/tp-logo-inverter.png'
import AppWrap from '../../lib/AppWrap'
import {
  logout,
  userMailApi,
  mailFolderApi
} from '../../services/Api'
import {Route, Switch} from 'react-router-dom'
import { MessageCard, MessageForm } from '../Message'
import { TicketForm } from '../Ticket'
import { MailFolderCard } from '../MailFolder'
import { SharedBoxCard, SharedBoxRelation } from '../SharedBox'
import { SharedBoxesList, SharedBoxTabs } from '../Admin'
import { DepartmentsList, DepartmentTabs } from '../Department'
import { CategoryTabs, CategoriesList } from '../Category'
import { UserMailsList, UserMailForm, UserMailSignatureForm } from '../UserMail'

const handleLogout = async () => {
  await logout()
}

const makeMailFoldersItems = (mailFolders = []) => {
  if (mailFolders.length <= 0) {
    return []
  }
  return mailFolders.map(mailFolder => {
    return {
      pathname: `/mailfolders/${mailFolder.id}`,
      exact: true,
      label: mailFolder.displayName,
      items: mailFolder.childFolderCount ? makeMailFoldersItems(mailFolder.childfolders) : []
    }
  })
}

const orderMailBox = (data) => {
  return data.sort((x) => {
    if (x.displayName === 'Caixa de Entrada' || x.displayName === 'Inbox') {
      return -1
    }
    return 0
  })
}

const Home = ({classes, ...props}) => {
  const [menuItems, setMenuItems] = useState()
  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage['user'])
      const responseSharedBox = await userMailApi.getSharedBoxes(user.userId)
      let menu = [
        {
          icon: HomeIcon,
          pathname: `/` ,
          label: 'Home'
        }
      ]
      if (responseSharedBox.ok) {
        for (const sharedBox of responseSharedBox.data) {
          const where = {
            and: [
              {sharedBoxId: sharedBox.id},
              {depth: 0}
            ]
          }
          const responseMailFolders = await mailFolderApi.getList({where})
          let items = makeMailFoldersItems(orderMailBox(responseMailFolders.data))
          if (user.realm !== 'admin'){
            items = [items[0]]
          }
          menu.push(
            {
              icon: Receipt,
              pathname: `/sharedBoxes/${sharedBox.id}`,
              label: sharedBox.email.split('@')[0],
              items
            }
          )
        }
      }
      menu.push(
        {
          icon: AddBox,
          pathname: `/sharedBoxes-relation`,
          label: 'Shared box'
        }
      )
      if (user.realm === 'admin'){
        menu.push(
          {
            group: true,
            label: 'Administrador',
            icon: SupervisorAccount,
            pathname: '',
            items: [
              {
                icon: AccountBalance,
                pathname: `/departments`,
                label: 'Departamentos'
              },
              {
                icon: Mail,
                pathname: `/sharedBoxesAdmin`,
                label: 'Shared Boxes'
              },
              {
                icon: Category,
                pathname: `/categories`,
                label: 'Categorias'
              },
              {
                icon: Person,
                pathname: `/userMails`,
                label: 'Usuários'
              }
            ]
          }
        )
      }
      menu.push(
        {
          icon: WrapText,
          pathname: `/userSignature`,
          label: 'Assinatura'
        }
      )
      setMenuItems(menu)
    }
    fetchData()
  }, [props.history.location.pathname])

  const createListNavigationActions = (history, name) => ({
    onClickNew: () => history.push(`/${name}/new`),
    onClickEdit: item => history.push(`/${name}/${item.id}`)
  })

  return (
  <AppWrap
    logo={<img src={logo} alt='Logo G10' style={{width: 40}}/>}
    onLogout={handleLogout}
    menuItems={menuItems}>
    <Switch>
      <Route path='/messages/:id' exact component={MessageCard} />
      <Route path='/messages-edit' exact component={MessageForm} />
      <Route path='/tickets/:id' exact component={TicketForm} />
      <Route path='/mailfolders/:id' exact component={MailFolderCard} />
      <Route path='/sharedBoxes/:id' exact component={SharedBoxCard} />
      <Route path='/sharedBoxes-relation' exact component={SharedBoxRelation} />
      <Route path='/sharedBoxesAdmin' exact render={props =>
          <SharedBoxesList withPaper {...props} {...createListNavigationActions(props.history, 'sharedBoxesAdmin')}/>
      }/>
      <Route path='/sharedBoxesAdmin/:id' component={SharedBoxTabs} />
      <Route path='/categories' exact render={props =>
          <CategoriesList withPaper {...props} {...createListNavigationActions(props.history, 'categories')}/>
      }/>
      <Route path='/categories/:id' component={CategoryTabs} />
      <Route path='/departments' exact component={DepartmentsList} />
      <Route path='/departments/:id' component={DepartmentTabs} />
      <Route path='/userMails' exact component={UserMailsList} />
      <Route path='/userMails/:id' exact component={UserMailForm} />
      <Route path='/userSignature' exact component={UserMailSignatureForm} />
    </Switch>
  </AppWrap>
)}

export default Home
